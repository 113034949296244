.project {
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(12, 1fr);
  margin-bottom: 40px;
}

.projects-title {
  font-size: clamp(30px, 8vw, 60px);
  font-family: 'SourceCodePro', 'Courier New', Courier, monospace;
  color: var(--text-main);
  width: 100%;
  text-align: left;
  margin-bottom: 35px;
}

.project-num {
  grid-column: 2 / 4;

  font-size: clamp(40px, 8vw, 80px);
  font-family: 'SourceCodePro', 'Courier New', Courier, monospace;
  margin: auto;
}

.dot {
  color: var(--text-highlight);
}

.project-card {
  grid-column: 5 / -2;

  position: relative;
  display: flex;
  max-width: 100%;
  height: 400px;
  background: var(--bg-box);
  box-shadow: 0 10px 10px 0 var(--bg-shadow);
  transition: var(--transition);
  padding: 10px;
  border-radius: 10px;
  overflow: hidden;
}

.num-even {
  grid-column: 10 / -2;
}

.card-even {
  grid-column: 2 / 9;
}

.card {
  width: 100%;
  min-height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.card-img {
  height: 90%;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
}

.card-content {
  margin: auto 0;
  font-family: 'SourceCodePro', 'Courier New', Courier, monospace;
  transition: var(--transition);
}

.img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
  object-position: center;
  transition: var(--transition);
}

.overlay {
  position: absolute;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  inset: 0;
  width: 100%;
  height: 100%;
  background-color: var(--bg-card-hover);
  z-index: 20;
  transition: var(--transition);
  transform: translateY(-500px);
}

.overlay-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.overlay-content > h2 {
  font-family: 'SourceCodePro', 'Courier New', Courier, monospace;
  align-self: flex-start;
  margin-bottom: 10px;
  padding: 0 10px;
  color: var(--bg-color);
}

.overlay-content > p {
  align-self: flex-start;
  margin-bottom: 20px;
  padding: 0 10px;
  font-weight: 500;
  color: var(--bg-color);
}

.overlay-links {
  display: flex;
  justify-self: flex-end;
}

.hover {
  border: 2px solid var(--bg-color);
  background-color: var(--bg-color);
  color: var(--text-main);
  border-radius: 50%;
  padding: 10px;
  aspect-ratio: 1 / 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
  transition: var(--transition);
}

.frameworks {
  display: flex;
  width: 100%;
  justify-content: space-around;
  font-family: 'SourceCodePro', 'Courier New', Courier, monospace;
  color: var(--bg-color);
  font-size: 12px;
  margin-bottom: 20px;
}

.hover:hover {
  background-color: var(--text-highlight);
  color: var(--bg-color);
  scale: 1.1;
}

.hover-icon {
  font-size: 22px;
}

.project-card:hover .overlay {
  transform: translateY(0px);
}

.project-card:hover .card-content {
  display: none;
  transition: var(--transition);
}

.project-card:hover .card-img {
  height: 100%;
  transition: var(--transition);
}

.project-card:hover .img {
  scale: 1.2;
  filter: blur(4px);
  transition: var(--transition);
}

/* more */
.other-title {
  font-size: clamp(30px, 8vw, 60px);
  font-family: 'SourceCodePro', 'Courier New', Courier, monospace;
  color: var(--text-main);
  width: 100%;
  text-align: right;
  margin-bottom: 35px;
}

.more-projects {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.other-projects-list {
  width: 100%;
  display: grid;
  grid-template-columns: repeat( auto-fill, minmax(250px, 1fr) );
  gap: 15px;
}

.other-project {
  padding: 20px;
  background: var(--bg-box);
  border-radius: 10px;
  box-shadow: 0 10px 10px 0 var(--bg-shadow);
  position: relative;
  min-height: 300px;
}

.other-project:hover {
  transform: translateY(-2px);
}

.other-card-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 100%;
  position: relative;
}

.other-card-head {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 35px;
}

.other-card-main > h2 {
  font-size: 22px;
  font-family: 'SourceCodePro', 'Courier New', Courier, monospace;
  margin-bottom: 10px;
}

.other-card-main > p {
  font-size: 17px;
  color: var(--text-muted);
  margin-bottom: 10px;
}

.folder-icon {
  color: var(--text-highlight);
  font-size: 35px;
}

.other-hover {
  margin-left: 10px;
  color: var(--text-main);
}

.other-hover:hover {
  color: var(--text-highlight);
}

.other-icon {
  font-size: 22px;
}

.italic {
  font-size: 12px;
  font-style: italic;
  color: var(--text-muted);
  display: block;
}

.other-card-footer {
  margin-top: 10px;
}

.other-frameworks {
  display: flex;
  align-items: flex-end;
  font-family: 'SourceCodePro', 'Courier New', Courier, monospace;
  color: var(--text-highlight);
  font-size: 12px;
}

.other-frameworks > li {
  margin-right: 15px;
}

.view-more {
  margin-top: 40px;
}

.project-list-mobile {
  display: none;
}

@media (max-width: 768px) {
  .project-list {
    display: none;
  }

  .project-list-mobile {
    display: block;
  }

  .mobile-project {
    position: relative;
    width: 100%;
    min-height: 60vh;
    background-color: var(--bg-box);
    box-shadow: 0 10px 10px 0 var(--bg-shadow);
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 40px;
  }

  .mobile-num {
    position: absolute;
    font-family: 'SourceCodeProBold', 'Courier New', Courier, monospace;
    font-size: 30px;
    margin: auto;
    background-color: var(--text-highlight);
    padding: 10px;
  }

  .left-num {
    top: 0px;
    left: 0px;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .right-num {
    top: 0px;
    right: 0px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  .num-mob {
    color: var(--bg-color);
  }

  .dot-mob {
    color: var(--text-main);
  }

  .mobile-img {
    height: 30%;
  }

  .mobile-card-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  .mobile-card-info > h2 {
    font-size: 22px;
    font-family: 'SourceCodePro', 'Courier New', Courier, monospace;
    margin-bottom: 10px;
    text-align: left;
    width: 100%;
    margin: 10px 0px;
  }

  .mobile-card-info > p {
    font-size: 17px;
    color: var(--text-muted);
    margin-bottom: 20px;
  }

  .frameworks-mob {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px 20px;
    font-family: 'SourceCodePro', 'Courier New', Courier, monospace;
    font-size: 12px;
    text-align: center;
    color: var(--text-highlight);
    margin-bottom: 20px;
  }
}