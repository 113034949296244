.intro-text {
  /* font-size: clamp(var(--fz-sm),5vw,var(--fz-md)); */
  color: var(--text-muted);
  margin: 0px 0px 20px 4px;
  /* font-family: 'Inter', Arial, Helvetica, sans-serif; */
  font-family: 'SourceCodePro', 'Courier New', Courier, monospace;
}

.lg-head {
  font-size: clamp(40px, 8vw, 80px);
  /* font-family: 'SourceCodeProBold'; */
  /* word-spacing: -0.3em; */
  font-family: 'Inter', Arial, Helvetica, sans-serif;
}

.my-name {
  color: var(--text-highlight);
}

.title {
  color: var(--text-main);
}

.description {
  color: var(--text-muted);
  max-width: 540px;
  margin-top: 20px;
  font-family: 'SourceCodePro', 'Courier New', Courier, monospace;
  /* font-family: 'Inter', Arial, Helvetica, sans-serif; */
}


