.left-tile {
  width: 40px;
  position: fixed;
  bottom: 0px;
  left: 40px;
  right: auto;
  transition: var(--hide);
}

.social-links::after {
  content: '';
  display: block;
  width: 1px;
  height: 90px;
  margin: 0px auto;
  background-color: var(--text-main);
}

.link-item {
  margin: 10px 0;
  font-size: 22px;
  padding: 10px;
}

.right-tile {
  width: 40px;
  position: fixed;
  bottom: 0px;
  right: 40px;
  left: auto;
  transition: var(--hide);
}

.scroll-el {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.scroll-el::after {
  content: '';
  display: block;
  width: 1px;
  height: 90px;
  margin: 0px auto;
  background-color: var(--text-main);
}


.scroll {
  margin: 5px 0;
  padding: 10px;
  font-size: 15px;
  writing-mode: vertical-lr;
  line-height: 18px;
  letter-spacing: 0.1em;
  font-family: 'SourceCodePro', 'Courier New', Courier, monospace;
  transform: translate(2px, 0px);
}


.so-a {
  color: var(--text-main);
}

.so-a:hover, .link-item:hover {
  color: var(--text-highlight);
  transform: translate(0px, -2px);
}

.icon {
  rotate: 90deg;
  animation: bounce 0.5s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  margin-bottom: 20px;
}


@keyframes bounce {
  from { transform: translate3d(5px, 0, 0);}
  to { transform: translate3d(0, 0, 0);}
}

.hide {
  scale: 2;
  opacity: 0;
}

@media (max-width: 768px) {
  .left-tile, .right-tile {
    display: none;
  }
}
