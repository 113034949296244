.contact-section {
  min-height: calc(100vh - 80px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-title {
  font-size: clamp(30px, 8vw, 60px);
  font-family: 'SourceCodePro', 'Courier New', Courier, monospace;
  color: var(--text-main);
  width: 100%;
  text-align: left;
  margin-bottom: 35px;
}

.contact-content {
  padding: 30px;
  background-color: var(--bg-color);
  border-radius: 10px;
  position: relative;
  transform-style: preserve-3D;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contact-content:before {
  content: '';
  width: 50%;
  height: 50%;
  border-top-left-radius: 10px;
  background-color: var(--text-highlight);
  position: absolute;
  top: -2px;
  left: -2px;
  transform: translateZ(-1px)
}

.contact-content:after {
  content: '';
  width: 50%;
  height: 50%;
  border-bottom-right-radius: 10px;
  background-color: var(--text-highlight);
  position: absolute;
  bottom: -2px;
  right: -2px;
  transform: translateZ(-1px)
}

.contact-content > h3 {
  font-size: clamp(25px, 8vw, 40px);
  margin-bottom: 25px;
  color: var(--text-highlight);
}

.contact-content > p {
  color: var(--text-muted);
  max-width: 550px;
  text-align: center;
  margin-bottom: 25px;
}

.contact-content > a {
  margin-bottom: 30px;
}

.social-icons {
  display: flex;
  gap: 20px;
}

.contact-link {
  color: var(--text-main);
}

.contact-link:hover, .social-item:hover {
  color: var(--text-highlight);
  transform: translateY(-2px);
}

.contact-icon {
  font-size: 30px;
}