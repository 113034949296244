@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

@font-face {
  font-family: SourceCodePro;
  src: url('/src/assets/fonts/SourceCodePro-Regular.ttf');
}

@font-face {
  font-family: SourceCodeProBold;
  src: url('/src/assets/fonts/SourceCodePro-Bold.ttf');
}

.App {
  color: var(--text-main);
  min-height: 100vh;
  background-color: var(--bg-color);
  transition: var(--transition);
  font-family: "Inter", 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
  scroll-behavior: smooth;
}

:root {
  --fz-sm: 14px;
  --fz-md: 16px;
  --transition: all 0.25s cubic-bezier(0.65, 0.05, 0.36, 1);
  --hide: all 500ms ease-in-out;;
  --bg-color: #1b1b1e;
  --bg-box: #1f1f21;
  --bg-shadow: #131314;
  --bg-opacity:#1b1b1ed9;
  --bg-card-hover: #69c7d6ca;
  --text-main: #D8DBE2;
  --text-muted: #a9bcc4;
  --text-highlight: #69c8d6;
  --text-highlight-hover: #69c7d639;
}

.main-pages {
  min-height: 100vh;
  padding: 0px 150px;
}

.blur {
  filter: blur(2px);
}

.main-section {
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  min-height: 100vh;
  max-width: 1000px;
  padding: 100px 0;
}

.highlight-button {
  background-color: transparent;
  border: 1px solid var(--text-highlight);
  color: var(--text-highlight);
  font-family: 'SourceCodePro', 'Courier New', Courier, monospace;
  padding: 10px 15px;
  border-radius: 5px;
}

.highlight-button:hover {
  background-color: var(--text-highlight-hover);
}

@media (max-width: 1080px) {
  .main-pages {
      padding: 0px 100px;
  }
}
@media (max-width: 768px) {
  .main-pages {
      padding: 0px 50px;
  }
}
@media (max-width: 480px) {
  .main-pages {
      padding: 0px 25px;
  }
}