.footer {
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer > p {
  font-size: var(--fz-sm);
  font-family: 'SourceCodePro', 'Courier New', Courier, monospace;
}

.portfolio-git {
  color: var(--text-muted);
}

.portfolio-git:hover {
  color: var(--text-highlight);
}