.header {
  width: 100%;
  height: 100px;
  background-color: var(--bg-opacity);
  backdrop-filter: blur(10px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 50px;
  position: fixed;
  top: 0;
  z-index: 30;
  transition: var(--transition);
}

.header-scrolled {
  box-shadow: 0 10px 30px -10px var(--bg-shadow);
  height: 80px;
}

.hide {
  transform: translateY(calc(80px) * -1);
}

.navlist {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navlist-item {
  margin: 20px;
}

.navlist-item > a:not(.highlight-button) {
  color: var(--text-main);
}

.navlist-item > a:hover {
  color: var(--text-highlight);
}

.ham-button {
  padding: 15px;
  position: relative;
  background-color: transparent;
  color: inherit;
  border: 0px;
  text-transform: none;
  display: none;
  cursor: pointer;
}

.ham-box {
  display: inline-block;
  width: 30px;
  height: 30px;
  position: relative;
}

.ham-inner {
  position: absolute;
  width: 100%;
  height: 2px;
  top: 50%;
  right: 0px;
  background-color: var(--text-main);
  transition: var(--transition);
  border-radius: 10px;
}

.ham-inner::before {
  content: '';
  display: block;
  position: absolute;
  height: 2px;
  width: 120%;
  background-color: var(--text-main);
  top: -10px;
  right: 0px;
  transition: var(--transition);
  border-radius: 10px;
}

.ham-inner::after {
  content: '';
  display: block;
  position: absolute;
  height: 2px;
  width: 80%;
  background-color: var(--text-main);
  bottom: -10px;
  right: 0px;
  transition: var(--transition);
  border-radius: 10px;
}

.ham-button.is-active .ham-inner::before {
  top: 50%;
  width: 100%;
  transform: rotate(270deg);
  background-color: var(--text-highlight);
}

.ham-button.is-active .ham-inner {
  transform: rotate(45deg);
  background-color: var(--text-highlight);
}

.ham-button.is-active .ham-inner::after {
  top: 50%;
  width: 100%;
  transform: rotate(-270deg);
  background-color: var(--text-highlight);
}

@media (max-width: 1080px) {
  .header {
    padding: 0px 40px;
  }
}

@media (max-width: 768px) {
  .header {
    padding: 0px 25px;
  }

  .ham-button {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 60;
  }

  .nav-links {
    position: absolute;
    height: 100vh;
    width: min(75vw, 400px);;
    z-index: 50;
    background-color: var(--bg-box);
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    padding-top: 100px;
    /* justify-content: center; */
    align-items: center;
    transition: var(--transition);
    transform: translateX(100%);
  }

  .navlist-item > a {
    font-size: 22px;
  }

  .show {
    transform: translateX(0);
  }
  
  .hidden {
    transform: translateX(100%);
  }

  .navlist {
    flex-direction: column;
  }

  
}

@media (max-width: 480px) {
  .header {
    padding: 0px 10px;
  }
}