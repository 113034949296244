.about-title {
  font-size: clamp(30px, 8vw, 60px);
  font-family: 'SourceCodePro', 'Courier New', Courier, monospace;
  color: var(--text-main);
  width: 100%;
  text-align: right;
  margin-bottom: 35px;
}

.intro {
  font-size: clamp(var(--fz-sm),5vw,var(--fz-md));
  color: var(--text-muted);
  font-family: 'SourceCodePro', 'Courier New', Courier, monospace;
}

.about-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.text {
  padding: 30px;
  width: 50%;
  background-color: var(--bg-color);
  border-radius: 10px;
  position: relative;
  transform-style: preserve-3D;
}

.text:before {
  content: '';
  width: 50%;
  height: 50%;
  border-top-left-radius: 10px;
  background-color: var(--text-highlight);
  position: absolute;
  top: -2px;
  left: -2px;
  transform: translateZ(-1px)
}

.text:after {
  content: '';
  width: 50%;
  height: 50%;
  border-bottom-right-radius: 10px;
  background-color: var(--text-highlight);
  position: absolute;
  bottom: -2px;
  right: -2px;
  transform: translateZ(-1px)
}

.highlight {
  color: var(--text-highlight);
  position: relative;
}

.highlight::after {
  content: '';
  position: absolute;
  width: 100%;
  background-color: var(--text-highlight);
  height: 1px;
  bottom: 0;
  left: 0;
  transform-origin: bottom right;
  transition: transform 250ms ease-in-out;
  transform: scaleX(0);
}

.text:hover .highlight:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.text > p {
  margin-bottom: 15px;
  color: var(--text-muted);
}

.tech-list {
  padding: 0 10px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0 10px;
}

.tech-item {
  /* list-style-type: '// '; */
  color: var(--text-highlight);
  font-size: var(--fz-sm);
  font-family: 'SourceCodePro', 'Courier New', Courier, monospace;
}

.tech-item::before {
  content: '// ';
  color: var(--text-muted);
}

.image-container {
  width: 40%;
  height: 100%;
  aspect-ratio: 1 / 1.1;
  border-radius: 10px;
  margin: auto;
  position: relative;
  z-index: 0;
  overflow: hidden;
}

.image {
  width: 100%;
  aspect-ratio: 1 / 1.1;
  object-fit: cover;
  object-position: top;
  z-index: 20;

  scale: 1.3;
  transition: ease-in-out 200ms;
  filter: grayscale(100%);
}

.image:hover {
  scale: 1;
  object-position: top;
  filter: none;
}


@media (max-width: 768px) {
  .about-content {
    flex-direction: column;
  }

  .text {
    width: 100%;
    margin-bottom: 30px;
  }

  .image-container {
    width: 60%;
  }
}

@media (max-width: 480px) {
  .image-container {
    width: 90%;
  }
}
